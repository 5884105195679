<template>
  <div class="Video content_center">
    <div class="left_video">
      <div class="info">
        <div class="i_left i_box">
          <div class="avatar" :style="`background: ${liveId ? '' : '#005aa4'}`">
            <img
              :src="
                liveId ? liveData.coverimg : require('@/assets/imgs/logo.png')
              "
              :style="
                liveId
                  ? ''
                  : 'height: auto;position: relative;top: 50%; left: 0;transform: translateY(-50%);'
              "
              alt=""
            />
          </div>
          <div class="name">{{ liveId ? liveData.title : "纽脉医疗" }}</div>
          <img src="@/assets/imgs/live/p1_1.png" alt="" />
        </div>
        <div class="i_right i_box" v-if="liveId">
          <div class="people_num">
            <img src="@/assets/imgs/live/p1_2.png" alt="" />
            <span>{{ watchUserNum }}</span>
          </div>
          <div class="like_num">
            <img src="@/assets/imgs/live/p1_3.png" alt="" />
            <span>{{ liveData.stars }}</span>
          </div>
          <img
            class="share"
            @click="onShareLyaerShow"
            src="@/assets/imgs/live/p1_4.png"
            alt=""
          />
          <img
            class="set"
            v-if="isZhubo && lookBack != 'lookBack'"
            @click="zhuboSetShow = true"
            src="@/assets/imgs/live/p1_5.png"
            alt=""
          />
        </div>
      </div>
      <div class="video_box" v-if="!isLiveOver || lookBack == 'lookBack'">
        <div id="svgaPlay" v-show="isSvga"></div>
        <video
          class="live_video"
          v-if="lookBack == 'lookBack'"
          :src="lookUrl"
          autoplay
          controls
        ></video>
        <!-- <video
          class="live_video"
          v-else-if="liveData.action == 3"
          :src="liveData.backurl[0] || ''"
          controls
        ></video> -->
        <img
          v-else-if="liveData.action == 1"
          class="live_video_img"
          :src="liveData.coverimg"
        />
        <vue-aliplayer-v2
            v-else-if="liveData.action == 2 && VideoRtc"
            :source="VideoRtc"
            :options="VideoRtcOptions"
            ref="VueAliplayerV2"
            style="height: 100%"
        />
<!--        <video-->
<!--          id="videoEl"-->
<!--          v-else-if="liveData.action == 2"-->
<!--          class="live_video"-->
<!--          controls-->
<!--          autoplay-->
<!--          muted-->
<!--        ></video>-->

        <div ref="bulletChat" class="bulletChat"></div>
        <div
          class="yugao"
          v-if="
            liveData.action == 1 &&
            new Date(liveData.date) - new Date() > 0 &&
            lookBack != 'lookBack'
          "
        >
          {{ liveData.title }} <br /><br />
          开播倒计时：{{ liveData.timeOut }}
        </div>
      </div>
      <div class="video_box live_over" v-else>
        <div style="height: 0; width: 0; overflow: hidden"></div>
        <div class="loock_back_box" v-if="overLookBackArr && overLookBackArr.length > 0">
          <div class="title">欢迎查看更多精彩回看</div>
          <div class="list">
            <div
              class="item"
              v-for="(item, key) in overLookBackArr"
              :key="key"
              @click="goLookback(item.id, item.type)"
            >
              <img class="img" :src="item.coverimg" alt="" />
              <div class="name">{{ item.title }}</div>
              <div class="date">{{ item.date }}</div>
            </div>
          </div>
        </div>
        <div class="tips">直播已结束</div>
      </div>
      <div class="lookBackComment" v-if="lookBack == 'lookBack'">
        <div class="look_pl_bottom">
          <div
            class="pl_or_dm"
            :style="`background: ${dmOrPl ? '#ececec' : ''}`"
          >
            <img
              class="icon"
              :class="dmOrPl ? 'left' : 'right'"
              :src="
                dmOrPl
                  ? require('@/assets/imgs/live/p3_6.png')
                  : require('@/assets/imgs/live/p3_5.png')
              "
              @click="dmOrPl = !dmOrPl"
            />
          </div>
          <input
            type="text"
            v-model="plTextarea"
            placeholder="发送弹幕"
            @keydown="handleKeyCode($event)"
          />
          <div class="fasong" @click="plFsClick">发送</div>
        </div>
      </div>
    </div>
    <div class="lookBack_right" v-if="lookBack == 'lookBack'">
      <div class="bulletChatList">
        <div class="title">
          <span>弹幕列表</span>
          <span @click="isBulletChatList = !isBulletChatList">{{
            isBulletChatList ? "展开" : "收起"
          }}</span>
        </div>
        <ul :class="'list ' + (!isBulletChatList ? 'activeList' : '')">
          <li>
            <span class="time">时间</span>
            <span class="text">发送内容 ({{ historyBulletChat.length }})</span>
            <span class="text">发送时间</span>
          </li>
          <li v-for="(item, index) in historyBulletChat" :key="index">
            <span class="time">{{ item.time }}</span>
            <span class="text" v-html="item.content"></span>
            <span class="text">{{ item.date }}</span>
          </li>
        </ul>
      </div>
      <div class="lookBackList">
        <div class="title">
          <span>回看列表</span>
          <span @click="isBulletChatList = !isBulletChatList">{{
            !isBulletChatList ? "展开" : "收起"
          }}</span>
        </div>
        <ul :class="'list ' + (isBulletChatList ? 'activeList' : '')">
          <li
            v-for="item in MyLookBack"
            :key="item.id"
            @click="lookClick(item.id)"
          >
            <div class="imgBox">
              <img :src="item.coverimg" alt="" />
            </div>
            <div class="text">{{ item.title }}</div>
          </li>
        </ul>
      </div>
    </div>
    <div class="right_pl" v-else-if="!isLiveOver">
      <video id="rtcvideo" v-if="rtcSubscrebe == 1"></video>
      <img
        class="closertc"
        @click="closeRtcs()"
        v-if="rtccloseBtn == 1"
        src="@/assets/imgs/live/p1_6.png"
        alt=""
      />
      <div class="nav_list" v-if="isPeixun">
        <div
          class="nav_item"
          :class="peixunNavActive == 0 ? 'active' : ''"
          @click="peixunNavActive = 0"
        >
          聊天
        </div>
        <div
          class="nav_item"
          :class="peixunNavActive == 1 ? 'active' : ''"
          @click="peixunNavActive = 1"
        >
          提问
        </div>
        <div
          class="nav_item"
          :class="peixunNavActive == 2 ? 'active' : ''"
          @click="peixunNavActive = 2"
        >
          公告
        </div>
      </div>
      <div v-if="peixunNavActive != 2">
        <div class="pl_wrapper" :class="isPeixun ? 'peixun_wrapper' : ''">
          <ul class="list" ref="pl_wrapper" @scroll="onPlScroll">
            <li class="item" v-for="(item, key) in plList" :key="key">
              <img class="avatar" :src="item.avatar" alt="" />
              <div class="info">
                <div class="name">{{ item.name }}</div>
                <div class="text" v-html="item.content"></div>
              </div>
            </li>
          </ul>
          <div class="user_on" v-if="!pl_wrapper_show">
            <span class="user_name">主播关闭聊天室功能</span>
          </div>
          <div class="user_on" v-else-if="joinLiveName">
            <span class="user_name">{{ joinLiveName }}</span> 加入了直播间
          </div>
        </div>

        <div class="pl_box">
          <div class="icon_box">
            <div class="left">
              <div class="gifts" v-show="isGifts">
                <div
                  class="gift"
                  v-for="(val, key) in giftDatas"
                  @click="sendgift(val)"
                  :key="key"
                >
                  <img :src="val.url" />
                  <div>{{ val.point }}积分</div>
                </div>
              </div>
              <div class="looks" v-show="isLooks">
                <img
                  @click="look(1)"
                  src="https://newmed.trisanet.com/img/look/1.png"
                />
                <img
                  @click="look(2)"
                  src="https://newmed.trisanet.com/img/look/2.png"
                />
                <img
                  @click="look(3)"
                  src="https://newmed.trisanet.com/img/look/3.png"
                />
                <img
                  @click="look(4)"
                  src="https://newmed.trisanet.com/img/look/4.png"
                />
                <img
                  @click="look(5)"
                  src="https://newmed.trisanet.com/img/look/5.png"
                />
                <img
                  @click="look(6)"
                  src="https://newmed.trisanet.com/img/look/6.png"
                />
                <img
                  @click="look(7)"
                  src="https://newmed.trisanet.com/img/look/7.png"
                />
                <img
                  @click="look(8)"
                  src="https://newmed.trisanet.com/img/look/8.png"
                />
                <img
                  @click="look(9)"
                  src="https://newmed.trisanet.com/img/look/9.png"
                />
                <img
                  @click="look(10)"
                  src="https://newmed.trisanet.com/img/look/10.png"
                />
                <img
                  @click="look(11)"
                  src="https://newmed.trisanet.com/img/look/11.png"
                />
                <img
                  @click="look(12)"
                  src="https://newmed.trisanet.com/img/look/12.png"
                />
                <img
                  @click="look(13)"
                  src="https://newmed.trisanet.com/img/look/13.png"
                />
                <img
                  @click="look(14)"
                  src="https://newmed.trisanet.com/img/look/14.png"
                />
                <img
                  @click="look(15)"
                  src="https://newmed.trisanet.com/img/look/15.png"
                />
                <img
                  @click="look(16)"
                  src="https://newmed.trisanet.com/img/look/16.png"
                />
                <img
                  @click="look(17)"
                  src="https://newmed.trisanet.com/img/look/17.png"
                />
                <img
                  @click="look(18)"
                  src="https://newmed.trisanet.com/img/look/18.png"
                />
                <img
                  @click="look(19)"
                  src="https://newmed.trisanet.com/img/look/19.png"
                />
                <img
                  @click="look(20)"
                  src="https://newmed.trisanet.com/img/look/20.png"
                />
                <img
                  @click="look(21)"
                  src="https://newmed.trisanet.com/img/look/21.png"
                />
                <img
                  @click="look(22)"
                  src="https://newmed.trisanet.com/img/look/22.png"
                />
                <img
                  @click="look(23)"
                  src="https://newmed.trisanet.com/img/look/23.png"
                />
                <img
                  @click="look(24)"
                  src="https://newmed.trisanet.com/img/look/24.png"
                />
                <img
                  @click="look(25)"
                  src="https://newmed.trisanet.com/img/look/25.png"
                />
              </div>
              <img
                src="@/assets/imgs/live/p3_1.png"
                alt=""
                @click="isGifts = !isGifts"
              />
              <!-- <img
                src="@/assets/imgs/live/p3_2.png"
                @click="isLooks = !isLooks"
                alt=""
              /> -->
            </div>
            <div class="fabulousWrap" @click="likeClick">
              <img src="@/assets/imgs/live/p5_1.png" />
              <img src="@/assets/imgs/live/p5_2.png" />
              <img src="@/assets/imgs/live/p5_1.png" />
              <img src="@/assets/imgs/live/p5_2.png" />
              <img src="@/assets/imgs/live/p5_1.png" />
              <img src="@/assets/imgs/live/p5_2.png" />
              <img src="@/assets/imgs/live/p5_1.png" />
              <img src="@/assets/imgs/live/p5_2.png" />
              <img src="@/assets/imgs/live/p5_1.png" />
              <img src="@/assets/imgs/live/p5_2.png" />
              <img src="@/assets/imgs/live/p5_1.png" />
              <img src="@/assets/imgs/live/p5_2.png" />
              <img src="@/assets/imgs/live/p5_1.png" />
              <img src="@/assets/imgs/live/p5_2.png" />
              <img src="@/assets/imgs/live/p5_1.png" />
              <img src="@/assets/imgs/live/p5_2.png" />
              <img src="@/assets/imgs/live/p5_1.png" />
              <img src="@/assets/imgs/live/p5_2.png" />
              <img src="@/assets/imgs/live/p5_1.png" />
              <img src="@/assets/imgs/live/p5_2.png" />
              <img src="@/assets/imgs/live/p5_1.png" />
              <img src="@/assets/imgs/live/p5_2.png" />
              <img src="@/assets/imgs/live/p5_1.png" />
              <img src="@/assets/imgs/live/p5_2.png" />
              <img src="@/assets/imgs/live/p5_1.png" />
              <img src="@/assets/imgs/live/p5_2.png" />
              <img src="@/assets/imgs/live/p5_1.png" />
              <img src="@/assets/imgs/live/p5_2.png" />
              <img src="@/assets/imgs/live/p5_1.png" />
              <img src="@/assets/imgs/live/p5_2.png" />
              <img src="@/assets/imgs/live/p5_1.png" />
              <img src="@/assets/imgs/live/p5_2.png" />
            </div>
            <!-- <img @click="likeClick" src="@/assets/imgs/live/p3_4.png" alt="" /> -->
          </div>
          <div class="area_box">
            <!-- <textarea
              class="pl_textarea"
              v-model="plTextarea"
              name=""
              id=""
              maxlength="100"
              @keydown="handleKeyCode($event)"
            ></textarea> -->
            <div
              class="pl_textarea"
              ref="pl_textareas"
              contenteditable="true"
              @keydown="handleKeyCode($event)"
              v-html="plTextarea"
            ></div>
            <!-- <img class="icon" src="@/assets/imgs/live/p3_3.png" alt="" /> -->
            <img class="icon"
                src="@/assets/imgs/live/p3_2.png"
                @click="isLooks = !isLooks"
                alt=""
              />
          </div>
          <div class="pl_bottom">
            <div
              class="pl_or_dm"
              :style="`background: ${dmOrPl ? '#ececec' : ''}`"
            >
              <img
                class="icon"
                :class="dmOrPl ? 'left' : 'right'"
                :src="
                  dmOrPl
                    ? require('@/assets/imgs/live/p3_6.png')
                    : require('@/assets/imgs/live/p3_5.png')
                "
                alt=""
                @click="dmOrPl = !dmOrPl"
              />
            </div>
            <div
              class="fasong"
              @click="plFsClick"
              :style="`background: ${pl_wrapper_show ? '#2ca6e0' : '#ccc'}`"
            >
              发送
            </div>
          </div>
        </div>
      </div>

      <div class="notices" v-if="peixunNavActive == 2">公告</div>
    </div>

    <transition name="el-fade-in-linear">
      <div class="share_layer layer_wrap" v-if="shareShow">
        <div class="content">
          <div class="title">分享地址</div>
          <div class="link_box">
            直播间地址：
            <el-input ref="hrefInput" v-model="liveHref" />
            <el-button type="primary" plain @click="copyHref">复制</el-button>
          </div>
          <div class="ewm" id="qrCode" ref="qrCodeDiv"></div>
          <img
            class="close"
            @click="shareShow = false"
            src="@/assets/imgs/live/p1_6.png"
            alt=""
          />
        </div>
      </div>
    </transition>
    <transition name="el-fade-in-linear">
      <div class="zhubo_layer layer_wrap" v-if="isZhubo" v-show="zhuboSetShow">
        <div class="content">
          <div class="title">主播功能</div>
          <div class="list">
            <div class="item" @click="closeChat">
              <div class="icon">
                <img src="@/assets/imgs/live/p2_1.png" alt="" />
              </div>
              <div class="text">
                {{ pl_wrapper_show ? "关闭" : "开启" }}聊天室
              </div>
            </div>
            <div class="item" @click="onQkLt">
              <div class="icon">
                <img src="@/assets/imgs/live/p2_2.png" alt="" />
              </div>
              <div class="text">清空聊天</div>
            </div>
            <div class="item" @click="onUserListShow">
              <div class="icon">
                <img src="@/assets/imgs/live/p2_3.png" alt="" />
              </div>
              <div class="text">用户列表</div>
            </div>
            <div class="item" @click="closeLive">
              <div class="icon end">
                <img src="@/assets/imgs/live/p2_4.png" alt="" />
              </div>
              <div class="text">结束直播</div>
            </div>
          </div>

          <img
            class="close"
            @click="zhuboSetShow = false"
            src="@/assets/imgs/live/p1_6.png"
            alt=""
          />
        </div>
      </div>
    </transition>
    <transition name="el-fade-in-linear">
      <div class="userList_layer layer_wrap" v-if="userListShow">
        <div class="content">
          <div class="title">用户列表</div>
          <div class="list">
            <div
              class="item"
              v-for="(item, key) in userList"
              v-if="key >= userListPage.start && key < userListPage.end"
              :key="key"
              @click="faqiDatiShow(item)"
            >
              <img class="avatar" :src="item.Avatar" alt="" />
              <div class="info">
                <div class="name">{{ item.Name }}</div>
                <div class="bumen">{{ item.Department }}</div>
              </div>
            </div>
          </div>
          <el-pagination
            class="pagination"
            :page-size="9"
            @current-change="userPageChange"
            layout="prev, pager, next"
            :total="Object.keys(userList).length"
          >
          </el-pagination>
          <img
            class="close"
            @click="userListShow = false"
            src="@/assets/imgs/live/p1_6.png"
            alt=""
          />
        </div>
      </div>
    </transition>
    <transition name="el-fade-in-linear">
      <div class="dati_layer layer_wrap" v-if="datiLayerShow">
        <div class="content">
          <img class="icon" src="@/assets/imgs/live/p4_1.png" alt="" />
          <div class="tips">
            老师邀请 {{ isZhubo ? student.Name : "你" }} 回答问题
          </div>
          <div class="btn" @click="faqiDati">
            {{ isZhubo ? "发起" : "接受" }}
          </div>
          <img
            class="close"
            @click="datiLayerShow = false"
            src="@/assets/imgs/live/p1_6.png"
            alt=""
          />
        </div>
      </div>
    </transition>

    <loginLayer
      ref="loginLayer"
      :layerShow="layerShow"
      @closeLayer="closeLayer"
    />
  </div>
</template>

<script>
import flvjs from "flv.js";
import VueAliplayerV2 from "vue-aliplayer-v2";
import "aliyun-webrtc-sdk";
import api from "@/api/serves/live";
import BulletChat from "@/assets/js/bulletChat.js";
import QRCode from "qrcodejs2";
import { getToken } from "@/utils/auth";
import { getVideoCurTime, getDateTime } from "@/utils/libs";
import loginLayer from "@/components/login";
import SVGA from "svgaplayerweb";
var shajs = require("sha.js");

import axios from "axios";
import qs from "qs";

export default {
  name: "Video",
  props: ["isPeixun", "lookBack"], // lookBack  回看
  components: {
    loginLayer,
    VueAliplayerV2
  },
  data() {
    return {
      VideoRtc: null,
      VideoRtcOptions: {
        encryptType: 1,
        isDrm: true,
        autoplay: true
      },
      liveId: "", //直播ID
      isBulletChatList: true, // 展开弹幕列表
      historyBulletChat: [], // 历史弹幕
      MyLookBack: [], // 回看列表数据
      lookUrl: "",
      isZhubo: false, //是否是主播
      liveData: {}, //直播信息
      liveHref: "", //直播链接
      shareShow: false, //分享链接显示
      plList: [], // 评论数组
      twList: [], //提问数组
      lastScrollTop: 0, //最后一次滚动的高度
      zhuboSetShow: false, //主播设置显示
      watchUserNum: 0, //在线观看人数
      userList: [], //用户列表
      userListShow: false, //用户列表显示
      userListPage: {
        start: 0,
        end: 9,
      },
      rtcSubscrebe: 2,
      dmOrPl: true, //弹幕或者评论
      plTextarea: "", //评论内容
      datiLayerShow: false, //发起答题显示
      student: {}, //被邀请的学生
      peixunNavActive: 0, //培训聊天框导航下标
      haveLogin: false, //有无登录
      plWebSocket: null, //评论列表
      joinLiveName: "", //加入直播间的名字
      joinLiveT: null, //加入直播间的定时器
      myPlayer: "", //直播播放器
      pl_wrapper_show: true, //是否关闭聊天
      layerShow: false, //登录弹框显示
      isLiveOver: false, //是否直播结束
      overLookBackArr: [], //直播结束后的回放列表
      isLooks: false, //是否显示表情列表
      rtccloseBtn: 2, //rtc关闭按钮
      isGifts: false, //礼物列表
      isSvga: false, //是否显示svga动画
      giftDatas:[],//礼物列表
    };
  },
  watch: {
    plList() {
      this.$nextTick(() => {
        if (this.$refs.pl_wrapper.scrollTop >= this.lastScrollTop) {
          this.$refs.pl_wrapper.scrollTop = this.$refs.pl_wrapper.scrollHeight;
          this.lastScrollTop = this.$refs.pl_wrapper.scrollTop;
        }
      });
    },
    peixunNavActive(val) {
      if (val == 0) {
        this.plList = this.plListTwo;
      } else if (val == 1) {
        this.plList = this.twList;
      }
      this.lastScrollTop = this.$refs.pl_wrapper.scrollTop;
    },
  },
  methods: {
    onPlScroll(e) {
      if (this.lastScrollTop < e.target.scrollTop) {
        this.lastScrollTop = e.target.scrollTop;
      }
    },
    handleKeyCode(event) {
      if (event.keyCode == 13 && event.ctrlKey) {
        //this.plTextarea += "\n";
      } else if (event.keyCode == 13) {
        event.preventDefault();
        this.plFsClick();
      }
    },
    getLiveData() {
      api.gifts().then((res) => {
        this.giftDatas = res;
      });
      api
        .live({ id: this.liveId })
        .then((res) => {
          // 没有权限
          let frg = this.playJurisdiction(res.isinside);

          if (frg == true) return;
          this.getPlList();
          this.liveData = res;
          this.pl_wrapper_show = Boolean(res.iscomment);
          // this.getUserList();

          if (res.etime * 1000 - new Date() < 0) {
            this.isLiveOver = true;
            this.getPlaybacks();
            return;
          }
          if(res.type == 2){
            this.$router.push('/trainLive?id='+this.liveId)
          }
          //初始化视频
          if (res?.action == 2 || new Date(res.stime * 1000) - new Date() < 0) {
            this.initRtc();
            // 新的 VideoRtc地址
            this.VideoRtc = res.VideoRtc
            this.liveData.action = 2

            // 注释掉旧的，不确定会不会有别的错误，不清楚具体的业务逻辑，暂时暴力注释掉的；
            // if (flvjs.isSupported()) {
            //   this.userWatch();
            //   this.$nextTick(() => {
            //     var videoEl = document.getElementById("videoEl");
            //     this.myPlayer = flvjs.createPlayer({
            //       type: "flv",
            //       isLive: true,
            //       hasAudio: true,
            //       url: res.videoFlv,
            //     });
            //
            //     this.myPlayer.attachMediaElement(videoEl);
            //     this.myPlayer.load();
            //     this.myPlayer.play();
            //   });
            // }
          } else if (res?.action == 1) {
            var vm = this;
            //未开播倒计时
            var startTime = new Date(res.date).getTime();
            getTime();
            res.t = setInterval(getTime, 1000);
            function getTime() {
              var nowTime = new Date().getTime(),
                time = (startTime - nowTime) / 1000;
              let h = Math.floor(time / 60 / 60),
                m = Math.ceil((time / 60) % 60);
              res.timeOut = h + "小时" + m + "分";
              console.log(time);
              //如果 开播倒计时到0 则重新请求直播信息
              if (time <= 0) {
                console.log("直播已开始");
                // vm.getLiveData();
                clearInterval(res.t);
              }
            }
          }
        })
        .catch((err) => {
          if (err.msg == "该直播仅限内部员工观看") {
            this.layerShow = true;
            this.$refs.loginLayer.onUserLoginShow("staff");
          }
        });
    },
    getPlList() {
      var vm = this;
      var liveId = this.liveId;
      if (vm.lookBack == "lookBack") {
        liveId = "bulletchat" + liveId;
      }
      this.plWebSocket = new WebSocket(
        "wss://newmedchat.trisanet.com/chat/?roomid=" +
          liveId +
          "&token=" +
          getToken()
      );
      this.plWebSocket.onopen = function () {
        //console.log("打开");
      };

      this.plWebSocket.onclose = function () {
        // vm.getPlList();
        //console.log("关闭");
      };

      this.plWebSocket.onmessage = function (data) {
        //console.log(data);
        var data = JSON.parse(data.data);
        if (data == "202") {
          vm.pl_wrapper_show = false;
        }

        if (data.type == "7") {
          //离开直播间
          vm.watchUserNum--;
        }

        if (data.T == 1) {
          // 加入直播间
          vm.joinLiveName = data.Name;

          vm.watchUserNum++;
          vm.joinLiveT = setTimeout(() => {
            vm.joinLiveT = null;
            vm.joinLiveName = "";
          }, 5000);
        } else {
          try {
            var Data = JSON.parse(data.Data);
            console.log(Data);
          } catch (error) {}
          if(!Data){
            return
          }
          if (Data.type == 1) {
            //用户评论
            //评论数组添加
            vm.plList.push({
              avatar: data.Avatar,
              name: data.Name,
              content: Data.data,
            });

            if (vm.dmOrPl) {
              vm.bulletChat.launch(Data.data);
            }
          } else if (Data.type == 2) {
            //提问
            //提问数组添加
            vm.twList.push({
              avatar: data.Avatar,
              name: data.Name,
              content: Data.data,
            });
          } else if (Data.type == "11") {
            vm.svgaPlay(Data.name);
            vm.plList.push({
              avatar: data.Avatar,
              name: data.Name,
              content: Data.data,
            });
          } else if (Data.type == "4") {
            //点赞
            vm.liveData.stars++;
          } else if (Data.type == "3") {
            //弹窗提问
            if (Data.name == vm.userInfo.id) {
              //如果被提问的是自己
              vm.datiLayerShow = true;
              vm.isZhubo = false;
              this.rtcPreview();
            }
          } else if (Data.type == "5") {
            //关闭聊天室
            vm.pl_wrapper_show = false;
          } else if (Data.type == "6") {
            //打开聊天室
            vm.pl_wrapper_show = true;
          } else if (Data.type == "closeLive") {
            vm.isLiveOver = true;
            vm.getPlaybacks();
          }
        }
      };

      this.plWebSocket.onerror = function () {
        vm.$message.error("聊天室连接失败");
        // setTimeout(() => {
        //   location.reload();
        // }, 1000);
      };

      api.chats({ id: this.liveId }).then((res) => {
        this.plList = res;
        this.plListTwo = res;
      });
    },
    copyHref() {
      this.$refs.hrefInput.$refs.input.select();
      document.execCommand("Copy"); // 执行浏览器复制命令
      this.$message.success("复制成功");
    },
    onUserListShow() {
      this.userListShow = true;
      this.zhuboSetShow = false;
      this.getUserList();
    },
    plFsClick() {
      if (this.lookBack != "lookBack") {
        this.changeText();
      }

      if (!this.pl_wrapper_show) return;
      if (!this.haveLogin) {
        this.$confirm("您好，请用公司邮箱登录进行评论", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        });
        return;
      }
      //console.log(this.plTextarea);
      if (!this.plTextarea) {
        return;
      }
      if (this.dmOrPl) {
        this.bulletChat.launch(this.plTextarea);
      }

      // 当前视频 播放的 时间
      let currentTime = 0;
      if (document.getElementsByClassName("live_video")[0]) {
        currentTime = Math.floor(
          document.getElementsByClassName("live_video")[0].currentTime
        );
      }

      let self = this;
      // 1聊天  2 提问  bulletchat 回看弹幕
      if (this.lookBack == "lookBack") {
        // 回看弹幕
        // this.plWebSocket.send({
        //   "type": "bulletchat",
        //   "date": self.plTextarea,
        //   "time": currentTime
        // });
        //console.log(currentTime)
        this.plWebSocket.send(
          `{"type":"bulletchat","data":"${self.plTextarea}", "time":${currentTime}}`
        );

        // console.log(
        //   `{"type":"bulletchat","data":"${self.plTextarea}", "time":"${currentTime}"}`
        // );

        this.historyBulletChat.push({
          time: getVideoCurTime(currentTime),
          content: this.plTextarea,
          date: getDateTime(),
        });
      } else {
        // 聊天 提问
        this.plWebSocket.send(
          `{"type":"${this.peixunNavActive == 0 ? "1" : "2"}","data":"${
            this.plTextarea
          }"}`
        );
      }

      this.user;
      this.plList.push({
        avatar: this.userInfo.avatar,
        name: this.userInfo.name,
        content: this.plTextarea,
      });
      setTimeout(() => {
        this.plTextarea = "";
      }, 60);

    },
    faqiDatiShow(user) {
      if (this.rtcSubscrebe == 1 || this.userInfo.id == user.Id) {
        return;
      }
      this.datiLayerShow = true;
      this.student = user;
    },
    faqiDati() {
      if (this.isZhubo) {
        this.plWebSocket.send(
          `{"type":"3","data":"老师邀请${this.student.Name}回答问题","name":"${this.student.Id}"}`
        );
        this.$message.success("发起成功");
      } else {
        this.rtcPreview();
        this.isZhubo = true;
      }
      this.datiLayerShow = false;
      this.userListShow = false;
    },
    onShareLyaerShow() {
      this.shareShow = true;
      this.$nextTick(function () {
        new QRCode(this.$refs.qrCodeDiv, {
          text: location.href,
          width: 200,
          height: 200,
          colorDark: "#333333", //二维码颜色
          colorLight: "#ffffff", //二维码背景色
          correctLevel: QRCode.CorrectLevel.L, //容错率，L/M/H
        });
      });
    },
    likeClick() {
      if (!this.haveLogin) {
        this.$confirm("您好，请登录后再进行操作", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        });
        return;
      }

      let oWrap = document.getElementsByClassName("fabulousWrap")[0],
        oImgs = oWrap.querySelectorAll("img");

      let arr = [
        "fabulousAnimation1",
        "fabulousAnimation2",
        "fabulousAnimation3",
        "fabulousAnimation4",
      ];

      oImgs[this.getRan(oImgs.length)].className = ` ${
        arr[this.getRan(arr.length)]
      }`;
      this.liveData.stars++;
      this.plWebSocket.send('{"type":"4","data":""}');
    },
    closeChat() {
      if (this.pl_wrapper_show) {
        this.plWebSocket.send('{"type":"5","data":""}');
        this.$message.success("关闭成功");
        this.pl_wrapper_show = false;
      } else {
        this.plWebSocket.send('{"type":"6","data":""}');
        this.$message.success("开启成功");
        this.pl_wrapper_show = true;
      }
      this.zhuboSetShow = false;
    },
    closeLive() {
      this.plWebSocket.send('{"type":"closeLive","data":""}');
      this.isLiveOver = true;
      this.getPlaybacks();
      this.$message.success("结束成功");
      this.zhuboSetShow = false;
    },
    onQkLt() {
      this.plList = [];
      this.plListTwo = [];
      this.twList = [];
      this.zhuboSetShow = false;
    },
    closeLayer() {
      this.layerShow = false;
    },
    getUserList() {
      api.getUserList({ roomid: this.liveId }).then((res) => {
        this.$nextTick(() => {
          this.watchUserNum = Object.keys(res).length;
          this.userList = res;
        });
      });
    },
    look(i) {
      this.changeText();
      this.plTextarea = this.plTextarea + "<img src='/img/look/" + i + ".png'>";
      this.isLooks = false;
    },
    sendgift(v) {
      var data = { id: v.name, token: getToken() };
      let that = this;
      axios
        .post("https://newmed.trisanet.com/api/gift", qs.stringify(data))
        .then(function (response) {
          response = response.data;
          console.log(response);
          if (response.code != 200) {
            that.$message({
              message: response.msg,
              type: "warning",
            });
          } else {
            var data = {};
            data["type"] = "11";
            data["data"] = "送出了" + v.name + "号礼物";
            data["name"] = v.name;
            data = JSON.stringify(data);
            that.plWebSocket.send(data);
            that.svgaPlay(v.name);
          }
        });
    },
    svgaPlay(id) {
      this.isSvga = true;
      let that = this;
      var player = new SVGA.Player("#svgaPlay");
      var parser = new SVGA.Parser("#svgaPlay");
      if(id==6||id==8||id==10){
        id = id+'-1';
      }
      parser.load(
        "https://newmed.trisanet.com/mobile/gifts/" + id + ".svga",
        function (item) {
          player.loops = 1;
          player.clearsAfterStop = true;
          player.setVideoItem(item);
          player.startAnimation();
          player.onFrame(function (i) {});
          player.onFinished(function () {
            that.isSvga = false;
          });
        }
      );
    },
    changeText() {
      this.plTextarea = this.$refs.pl_textareas.innerHTML;
      //console.log(this.plTextarea)
      setTimeout(() => {
        this.keepLastIndex(this.$refs.pl_textareas);
      }, 50);
    },
    keepLastIndex(e) {
      e.focus(); //解决ff不获取焦点无法定位问题
      var range = window.getSelection(); //创建range
      range.selectAllChildren(e); //range 选择obj下所有子内容
      range.collapseToEnd(); //光标移至最后
    },
    getRecord() {
      api
        .getRecord({ id: this.liveId })
        .then((res) => {
          var isinside = res.isinside; // 1:内部员工 0:非内部员工一般注册
          // 没有权限
          if (this.playJurisdiction(isinside) == true) return;
          this.liveData.title = res.title;
          this.liveData.coverimg = res.coverimg;
          //this.getLiveData();  //获取直播信息
          this.getPlList(); // 获取评论以及 建立ws
          // this.getUserList(); // 获取直播用户人数
          this.historyBulletChat = res.chats; // 弹幕列表
          this.MyLookBack = res.more; // 回看列表
          this.lookUrl = res.url; // 回看url地址
        })
        .then(() => {});
    },
    // 观看权限判断
    playJurisdiction(isinside) {
      // 分享视频观看判断
      if (this.userInfo) {
        // 已登陆但没权限
        if (this.userInfo.inside == 0 && isinside == 1) {
          this.$message({
            message: "该直播仅限内部员工观看,请登录。",
            type: "warning",
          });
          this.layerShow = true;
          this.$refs.loginLayer.onUserLoginShow("staff");

          return true; // 没权限
        }
      } else if (!this.userInfo) {
        // 未登录
        if (isinside == 0) {
          // 普通视频
          this.layerShow = true;
        } else {
          // 员工视频
          this.layerShow = true;
          this.$refs.loginLayer.onUserLoginShow("staff");
        }

        return true; // 没权限
      }
      return false;
    },
    userPageChange(e) {
      this.userListPage.start = 0 + (e - 1) * 9;
      this.userListPage.end = 9 + (e - 1) * 9;
    },
    getRan(max) {
      return Math.floor(Math.random() * max);
    },
    lookClick(id) {
      // 回看
      this.liveId = id;
      this.$router.push(
        `/lookBack?id=${id}&navActive=${this.$route.query.navActive}`
      );
      this.init();
    },
    init() {
      // 如果 user 信息存
      if (this.$store.state.user) {
        this.haveLogin = true; // 修改为登录状态
        this.userInfo = this.$store.state.user; // 获取用户信息
        if (this.userInfo.anchor) this.isZhubo = true;
      }
      //console.log(this.lookBack);
      if (this.lookBack == "lookBack") {
        // 回看
        this.getRecord();
      } else {
        this.getLiveData();
        this.getUserList();
      }

      this.liveHref = location.href;
    },
    initRtc() {
      console.log("init rpc");
      var aliWebrtc = new AliRtcEngine();
      this.aliWebrtc = aliWebrtc;
      aliWebrtc
        .isSupport()
        .then(() => {})
        .catch((error) => {
          console.log(error);
        });
      //加入房间
      var channelId = this.liveId;
      var appId = "ksxf2xsq";
      var key = "ef69a2b00b6202f3b5ad4f2ff617777f";
      var userId = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
          var r = (Math.random() * 16) | 0,
            v = c == "x" ? r : (r & 0x3) | 0x8;
          return v.toString(16);
        }
      );
      var timestamp = parseInt(new Date().getTime() / 1000 + 48 * 60 * 60);
      var nonce = "AK-" + timestamp;
      var token = appId + key + channelId + userId + nonce + timestamp;
      console.log(token);
      var token = shajs("sha256").update(token).digest("hex");
      var userName = Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, "")
        .substr(0, 5);
      console.log(userName);
      aliWebrtc
        .joinChannel(
          {
            userid: userId, // 用户ID，只能由数字、字母、下划线组成
            channel: channelId, // 频道
            appid: appId, // 应用ID
            nonce, // 随机码
            timestamp, // 时间戳
            gslb: ["https://rgslb.rtc.aliyuncs.com"], // gslb服务地址
            token, // 令牌
          },
          userName
        )
        .then(
          () => {
            console.log("rtc加入成功");
          },
          (error) => {
            // 入会失败，打印错误内容，可以看到失败原因
            console.log(error.message);
          }
        );
      //订阅推流回调
      aliWebrtc.on("onPublisher", (publisher) => {
        console.log(publisher.userId);
        this.rtcSubscrebe = 1;
        this.rtcSubscribe(publisher.userId);
      });
      aliWebrtc.on("onUnPublisher", (publisher) => {
        console.log(publisher);
        var rtcId = publisher.userId;
        this.rtcSubscrebe = 2;
        aliWebrtc.configRemoteCameraTrack(rtcId, true, false);
        aliWebrtc.configRemoteAudio(rtcId, false);
        aliWebrtc
          .subscribe(rtcId)
          .then((re) => {
            console.log("停止订阅成功");
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
    closeRtcs() {
      this.rtccloseBtn = 2;
      this.rtcSubscrebe = 2;
      var aliWebrtc = this.aliWebrtc;
      aliWebrtc.configLocalAudioPublish = false;
      aliWebrtc.configLocalCameraPublish = false;
      aliWebrtc.publish().then(
        (re) => {
          console.log("停止推流成功", "success");
        },
        (error) => {
          console.log("[停止推流失败]" + error.message);
        }
      );
    },
    rtcPreview() {
      this.rtcSubscrebe = 1;
      this.rtccloseBtn = 1;
      var aliWebrtc = this.aliWebrtc;
      aliWebrtc
        .startPreview(document.querySelector("#rtcvideo"))
        .then((obj) => {})
        .catch((error) => {
          showAlert("[开启预览失败]" + error.message, "danger");
        });
      aliWebrtc.configLocalAudioPublish = true;
      aliWebrtc.configLocalCameraPublish = true;
      aliWebrtc.publish().then(
        (res) => {
          console.log("发布流成功");
        },
        (error) => {
          console.log("推流失败");
        }
      );
    },
    rtcSubscribe(userId) {
      console.log("订阅" + userId + "推流");
      var aliWebrtc = this.aliWebrtc;
      aliWebrtc.configRemoteCameraTrack(userId, true, true);
      aliWebrtc.configRemoteAudio(userId, true);
      aliWebrtc.subscribe(userId).then(
        (userId) => {
          aliWebrtc.setDisplayRemoteVideo(
            userId, // userId是用户ID
            document.querySelector("#rtcvideo"), // html中用于显示stream对象的video元素
            1 // 1表示摄像头流（大流和小流）和音频流，2表示屏幕分享流
          );
        },
        (error) => {
          console.log(error.message);
        }
      );
    },
    goLookback(id, type) {
      this.$router.push(
        "/lookBack?id=" +
          id +
          "&navActive=" +
          (type == 1 ? "lookBack" : "trainLive")
      );
    },
    getPlaybacks() {
      api.playbacks().then((res) => {
        this.overLookBackArr = res;
      });
    },
    getId() {
      api.getnowId().then((res) => {
        this.liveId = res;
        if (!res) {
          this.isLiveOver = true;
          this.getPlaybacks();
          return;
        }
        this.init();
      });
    },
    userWatch() {
      setInterval(() => {
        api.userWatch({ liveid: this.liveId });
      }, 60000);
    },
  },
  mounted() {
    this.liveId = this.$route.query.id;
    if (this.liveId) {
      this.init();
    } else {
      this.getId();
    }

    this.bulletChat = new BulletChat({
      dom: this.$refs.bulletChat,
      max: Math.floor(this.$refs.bulletChat.offsetHeight / 20), // 一次弹幕最大条数  21是 item的高度
      cls: "item",
      activeCls: "activeItem",
    });
  },
  beforeDestroy() {
    this.plWebSocket.close();
  },
};
</script>

<style lang="less">
@skyBlue: #2ca6e0;
.closertc {
  position: fixed;
  right: 190px;
  top: calc(40%);
  width: 20px;
  z-index: 4;
}
#rtcvideo {
  position: fixed;
  top: 40%;
  right: 0;
  width: 200px;
  z-index: 3;
}
.Video {
  display: flex;
  justify-content: space-between;
  .left_video {
    position: relative;
    width: 925px;
    // border-radius: 10px;
    // background: #fff;
    overflow: hidden;
    flex: 1;

    .info {
      display: flex;
      justify-content: space-between;
      padding: 20px 0;
      border-radius: 10px 10px 0 0;
      background: #fff;

      .i_left {
        .avatar {
          width: 60px;
          height: 60px;
          border-radius: 50%;
          border: 1px solid #000;
          overflow: hidden;
          margin-left: 20px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .name {
          font-size: 18px;
          margin: 0 14px;
          max-width: 400px;
        }
      }

      .i_right {
        div {
          display: flex;
          align-items: center;
          font-size: 14px;
          margin-right: 20px;
          span {
            margin-left: 15px;
          }
        }

        .share,
        .set {
          margin-right: 30px;
          cursor: pointer;
        }
      }

      & > .i_box {
        display: flex;
        align-items: center;
      }
    }

    .video_box {
      position: relative;
      height: 540px;
      background: #fff;
      border-radius: 0 0 10px 10px;
      #svgaPlay {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
      }
    }

    .live_over {
      background: #1a3a8e;
      font-size: 24px;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: inset 0 0 70px 0 #000;

      .loock_back_box {
        width: 80%;

        .list {
          margin-top: 20px;
          display: flex;
          justify-content: space-between;
          .item {
            width: 300px;
            cursor: pointer;

            .img {
              height: 180px;
              border-radius: 10px;
            }

            .name {
              font-size: 18px;
              margin-top: 20px;
              overflow: hidden;
              text-overflow: ellipsis; //文本溢出显示省略号
              white-space: nowrap; //文本不会换行
            }

            .date {
              font-size: 14px;
              margin-top: 10px;
            }
          }
        }
      }

      .tips {
        position: absolute;
        bottom: 25px;
        right: 30px;
      }
    }

    .live_video {
      width: 100%;
      height: 540px;
      display: block;
      background: #000;
    }

    .live_video_img {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 540px;
    }
    .bulletChat {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 250px;
    }

    .yugao {
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      position: absolute;
      top: 0;
      left: 0;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 30px;
      text-align: center;
    }

    .lookBackComment {
      margin-top: 15px;
      height: 63px;
      background: #fff;
      padding: 15px;
      box-sizing: border-box;
      border-radius: 5px;

      .look_pl_bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        padding: 0 10px;
        transition: 0.3s all;

        .pl_or_dm {
          width: 55px;
          height: 25px;
          border: 1px solid #ccc;
          border-radius: 20px;
          position: relative;
          .right {
            position: absolute;
            left: 32px;
          }

          .left {
            left: 2px;
          }

          .icon {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            cursor: pointer;
            transition: 0.3s all;
          }
        }

        input {
          flex: 1;
          height: 100%;
          margin: 0 10px;
          background: #ebebeb;
          border: 1px solid #b7b7b7;
          border-radius: 3px;
          padding: 0 5px;
        }

        .fasong {
          width: 110px;
          text-align: center;
          line-height: 32px;
          background: @skyBlue;
          color: #fff;
          font-size: 14px;
          border-radius: 30px;
          cursor: pointer;
        }
      }
    }
  }

  // 回看右侧
  .lookBack_right {
    width: 310px;
    border-radius: 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    margin-left: 15px;

    .bulletChatList,
    .lookBackList {
      background: #fff;
      border-radius: 5px;

      .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 60px;
        padding: 0 0 0 15px;
        box-sizing: border-box;
        color: #999;

        :nth-child(1) {
          font-size: 18px;
        }

        :nth-child(2) {
          font-size: 14px;
          color: #666;
          cursor: pointer;
          padding: 20px;
        }
      }

      .list {
        height: 0;
        border-top: 1px solid #cccccc;
        box-sizing: border-box;
        overflow: hidden;
        transition: height 0.3s linear;
        padding: 0 20px;

        li:first-child {
          margin-top: 20px;
        }
        li:last-child {
          margin-bottom: 20px;
        }
      }
      .activeList {
        height: 585px;
        overflow: auto;
      }
    }

    .bulletChatList {
      .list {
        li {
          display: flex;
          justify-content: space-between;
          line-height: 30px;

          &:nth-child(1) {
            span:nth-child(2) {
              color: #999;
            }
          }

          span {
            color: #999;
            font-size: 13px;
            &:nth-child(1) {
              width: 60px;

              text-align: center;
            }
            &:nth-child(2) {
              flex: 1;
              margin: 0 5px;
              color: #000;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              img {
                width: 30px;
                height: 30px;
                vertical-align: middle;
                margin-left: 5px;
                display: inline;
              }
            }
            &:nth-child(3) {
              width: 90px;
              text-align: center;
            }
          }
        }
      }
    }

    .lookBackList {
      margin-top: 15px;

      .list {
        li {
          cursor: pointer;
          .imgBox {
            width: 100%;
            img {
              width: 100%;
            }
          }

          .text {
            margin-top: 15px;
            margin-bottom: 20px;
            font-size: 14px;

            -webkit-box-orient: vertical;
            word-break: break-all;
            overflow: hidden;
            -webkit-line-clamp: 2;
            display: -webkit-box;
          }
        }
      }
    }
  }

  .right_pl {
    width: 310px;
    border-radius: 10px;
    background: #fff;
    position: relative;
    display: flex;
    flex-direction: column;
    margin-left: 15px;

    .nav_list {
      display: flex;
      justify-content: space-around;
      font-size: 18px;
      color: #fff;
      background: @skyBlue;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      line-height: 50px;

      .nav_item {
        cursor: pointer;
        position: relative;
      }
      .active::after {
        content: "";

        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid #fff;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    .pl_wrapper {
      height: 475px;
      position: relative;

      &.peixun_wrapper {
        height: 425px;

        .list {
          max-height: 375px;
        }
      }

      .list {
        max-height: 425px;
        overflow: auto;

        // &::-webkit-scrollbar {
        //   /*滚动条整体部分，其中的属性有width,height,background,border等（就和一个块级元素一样）（位置1）*/
        //   width: 10px;
        // }
        // &::-webkit-scrollbar-thumb {
        //   /*滚动条里面可以拖动的那部分（位置5）*/
        //   background: #999;
        //   border-radius: 4px;
        // }
        & {
          // scrollbar-arrow-color: #f4ae21; /**//*三角箭头的颜色*/
          // scrollbar-face-color: #333; /**//*立体滚动条的颜色*/
          // scrollbar-3dlight-color: #666; /**//*立体滚动条亮边的颜色*/
          // scrollbar-highlight-color: #666; /**//*滚动条空白部分的颜色*/
          // scrollbar-shadow-color: #999; /**//*立体滚动条阴影的颜色*/
          // scrollbar-darkshadow-color: #666; /**//*立体滚动条强阴影的颜色*/
          // scrollbar-track-color: #666; /**//*立体滚动条背景颜色*/
          // scrollbar-base-color:#f8f8f8; /**//*滚动条的基本颜色*/
        }
      }
      .item {
        display: flex;
        flex-wrap: wrap;
        margin-top: 14px;
        padding: 0 6px;
        .avatar {
          width: 28px;
          height: 28px;
          border-radius: 50%;
          margin-right: 8px;
          margin-top: 3px;
        }

        .info {
          font-size: 8px;
          flex: 1;
          .text {
            height: 30px;
            line-height: 30px;
            img {
              width: 30px;
              height: 30px;
              vertical-align: middle;
              margin-left: 5px;
              display: inline;
            }
          }
        }
      }

      .user_on {
        font-size: 12px;
        position: absolute;
        bottom: 15px;
        left: 0;
        .user_name {
          margin-right: 10px;
          margin-left: 6px;
        }
      }
    }

    .pl_box {
      flex: 1;
      border-top: 1px solid #ccc;

      .icon_box {
        margin-top: 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 14px;

        .left {
          display: flex;
          align-items: center;
          position: relative;
          .looks {
            position: absolute;
            width: 300px;
            height: 250px;
            top: -270px;
            left: -10px;
            background: white;
            border-radius: 5px;
            img {
              width: 40px;
              height: 40px;
              float: left;
              margin: 5px;
            }
          }
          .gifts {
            position: absolute;
            width: 300px;
            height: 170px;
            top: -190px;
            left: -10px;
            background: white;
            border-radius: 5px;
            .gift {
              float: left;
              text-align: center;
              font-size: 14px;
              img {
                width: 40px;
                height: 40px;
                float: left;
                margin: 10px;
                display: inline;
              }
            }
          }
          img {
            margin-right: 12px;
            cursor: pointer;
          }
        }

        .fabulousWrap {
          cursor: pointer;
          position: relative;
          width: 30px;
          height: 26px;
          background: url("../../../assets/imgs/live/p3_4.png") center no-repeat;

          img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            transform: translate(0, 0);

            animation-timing-function: linear;
            animation-duration: 2s;
          }
        }
        // img {
        //   cursor: pointer;
        // }
      }
      .area_box {
        position: relative;
        .pl_textarea {
          width: 290px;
          height: 50px;
          margin: 0 auto;
          display: block;
          border-radius: 10px;
          border: 1px solid #999;
          margin-top: 10px;
          padding: 5px 50px 5px 5px;
          box-sizing: border-box;
          font-size: 12px;
          overflow: hidden;
          line-height: 30px;
          img {
            width: 30px;
            height: 30px;
            vertical-align: middle;
            margin-left: 5px;
            display: inline;
          }
        }

        .icon {
          position: absolute;
          top: 50%;
          right: 20px;
          transform: translateY(-50%);
        }
      }
    }

    .pl_bottom {
      margin-top: 15px;
      display: flex;
      justify-content: space-between;
      padding: 0 10px;
      transition: 0.3s all;

      .pl_or_dm {
        width: 55px;
        height: 25px;
        border: 1px solid #ccc;
        border-radius: 20px;
        position: relative;
        .right {
          position: absolute;
          left: 32px;
        }

        .left {
          left: 2px;
        }

        .icon {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          cursor: pointer;
          transition: 0.3s all;
        }
      }

      .fasong {
        width: 110px;
        text-align: center;
        line-height: 25px;
        background: @skyBlue;
        color: #fff;
        font-size: 14px;
        border-radius: 30px;
        cursor: pointer;
      }
    }

    .notices {
      padding: 20px 10px;
      font-size: 16px;
    }
  }

  .share_layer {
    .content {
      width: 765px;
      height: 190px;

      .title {
        position: absolute;
        top: 40px;
        left: 35px;
        font-size: 24px;
        color: @skyBlue;
      }

      .link_box {
        position: absolute;
        bottom: 40px;
        left: 35px;
        display: flex;
        align-items: center;

        .el-input {
          width: 295px;
          margin-left: 20px;
          margin-right: 30px;

          /deep/.el-input__inner {
            color: @skyBlue;
            font-size: 18px;
          }
        }

        .el-button {
          font-size: 18px;
        }
      }

      .ewm {
        width: 120px;
        height: 120px;
        position: absolute;
        right: 55px;
        top: 50%;
        margin-top: -60px;

        /deep/ img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .zhubo_layer {
    .content {
      width: 530px;
      height: 195px;

      .title {
        color: @skyBlue;
        font-size: 24px;
        margin-top: 20px;
        text-align: center;
      }

      .list {
        margin-top: 15px;
        display: flex;
        justify-content: space-around;

        .item {
          .icon {
            width: 85px;
            height: 85px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #ccc;
            border-radius: 50%;
            cursor: pointer;
            transition: 0.3s all;
            &:hover {
              background: @skyBlue;
            }
          }

          .end {
            background: #d9312d;
            &:hover {
              background: #d9312d;
            }
          }

          .text {
            font-size: 14px;
            text-align: center;
            margin-top: 5px;
          }
        }
      }
    }
  }

  .userList_layer {
    .content {
      width: 990px;
      height: 610px;

      .title {
        font-size: 24px;
        margin-top: 20px;
        margin-left: 40px;
      }

      .list {
        display: flex;
        flex-wrap: wrap;
        padding: 0 80px;
        .item {
          display: flex;
          align-items: center;
          margin-top: 60px;
          margin-right: 40px;
          cursor: pointer;
          .avatar {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            margin-right: 20px;
          }

          .info {
            width: 170px;
            font-size: 24px;

            .bumen {
              font-size: 18px;
              color: #999;
            }
          }

          &:nth-child(3n) {
            margin-right: 0;
          }
        }
      }

      .pagination {
        position: absolute;
        bottom: 30px;
        left: 50%;
        transform: translateX(-50%);

        /deep/ .number {
          font-size: 18px;
          margin: 0 10px;
          padding: 0;
          font-weight: 400;
          // min-width: 30px;
          // height: 22px;
          // line-height: 22px;
        }

        /deep/ .active {
          background: #000;
          color: #fff;
        }
      }
    }
  }

  .dati_layer {
    .content {
      width: 560px;
      height: 390px;

      .icon {
        margin: 60px auto 30px;
      }

      .tips {
        text-align: center;
        font-size: 24px;
      }

      .btn {
        margin: 30px auto 0;
        width: 300px;
        text-align: center;
        line-height: 68px;
        font-size: 24px;
        border: 1px solid #999;
        border-radius: 40px;
        cursor: pointer;
        transition: 0.3s all;
        &:hover {
          background: @skyBlue;
          color: #fff;
        }
      }
    }
  }

  .layer_wrap {
    width: 100%;
    height: 100%;
    position: fixed;
    background: rgba(0, 0, 0, 0.3);
    top: 0;
    left: 0;
    z-index: 999;

    .content {
      background: #fff;
      border-radius: 10px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;

      .close {
        position: absolute;
        right: 15px;
        top: 10px;
        cursor: pointer;
      }
    }
  }

  ::-webkit-scrollbar {
    /*滚动条整体部分，其中的属性有width,height,background,border等（就和一个块级元素一样）（位置1）*/
    width: 10px;
  }
  ::-webkit-scrollbar-thumb {
    /*滚动条里面可以拖动的那部分（位置5）*/
    background: #999;
    border-radius: 4px;
  }
}
</style>

<style lang="less">
.Video {
  .bulletChat {
    // height: 1px !important;
    .item {
      height: 30px;
      line-height: 30px;
      color: #fff;
      transform: translateX(100%);
      img {
        width: 30px;
        height: 30px;
        vertical-align: middle;
        margin-left: 5px;
        display: inline;
      }
    }
    .activeItem {
      transform: translateX(-100%);
      transition: all 7s linear;
    }
  }
}

.fabulousAnimation1 {
  animation-name: fabulous1;
}
.fabulousAnimation2 {
  animation-name: fabulous2;
}
.fabulousAnimation3 {
  animation-name: fabulous3;
}
.fabulousAnimation4 {
  animation-name: fabulous4;
}

@keyframes fabulous1 {
  0% {
    opacity: 1;
  }
  50% {
    transform: translate(30px, -80px);
  }
  80% {
    opacity: 1;
  }
  100% {
    transform: translate(-10px, -160px);
    opacity: 0;
  }
}
@keyframes fabulous2 {
  0% {
    opacity: 1;
  }
  50% {
    transform: translate(-30px, -70px);
  }
  80% {
    opacity: 1;
  }
  100% {
    transform: translate(10px, -140px);
    opacity: 0;
  }
}
@keyframes fabulous3 {
  0% {
    opacity: 1;
  }
  50% {
    transform: translate(20px, -60px);
  }
  80% {
    opacity: 1;
  }
  100% {
    transform: translate(0px, -120px);
    opacity: 0;
  }
}
@keyframes fabulous4 {
  0% {
    opacity: 1;
  }
  50% {
    transform: translate(-20px, -60px);
  }
  80% {
    opacity: 1;
  }
  100% {
    transform: translate(0px, -120px);
    opacity: 0;
  }
}
</style>
