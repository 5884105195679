
// 获取当前视频播放时分秒
export function getVideoCurTime(t) {
  let h = Math.floor(t / 60 / 60),  //小时
      m = Math.floor(t / 60 % 60),       //分
      s = Math.floor(t % 60);            //秒

  return setZero(h) + ':' + setZero(m) + ':' + setZero(s);
}

export function getDateTime () {
  let date = new Date()
  var moth = date.getMonth() + 1,
      dat = date.getDate(),
      hos = date.getHours(),
      mutes = date.getUTCMinutes();

  function setZero(num){
    if(num < 10){
      num = '0' + num
    }
    return num
  }

  return setZero(moth) + '-' + setZero(dat) + ' ' + setZero(hos) + ':' + setZero(mutes);
}

function setZero(num){  //时分秒 小于10  前面加0
  if(num < 10){
    num = '0' + num
  }
  return num
}
