import request from '../request';

export default {
    live(params) {
        return request.get('/live', { params })
    },
    chats(params) {
        return request.get('/chats', { params })
    },
    star(params) {
        return request.post('/star', params)
    },
    gifts(params) {
        return request.get('/gifts', params)
    },
    //关闭聊天室
    closeChat(params) {
        return request.post('/close/chat', params)
    },
    //关闭直播
    closeLive(params) {
        return request.post('/close/live', params)
    },
    getUserList(params) {
        return request.get('/users', { params })
    },
    getRecord(params) {
        return request.get('/record', { params })
    },
    playbacks(params) {
        return request.get('/playbacks', { params })
    },
    getnowId(params) {
        return request.get('/now', { params })
    },
    userWatch(params) {
        return request.post('/user/watch', params)
    }
}


