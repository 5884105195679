class BulletChat {
  constructor(opt) {
    this.dom = opt.dom;
    this.max = opt.max;
    this.cls = opt.cls;
    this.activeCls = opt.activeCls;
    this.oItems = null;

    this.free = []; // 空闲中弹幕

    this.init();
  }

  init() {
    this.addBulletChatItem();
    this.bindEvent()
  }

  bindEvent() {
    this.resetItemCls()
  }

  // 插入 弹幕元素
  addBulletChatItem() {
    let itemArr = []
    for (let i = 0; i < this.max; i++) {
      itemArr.push(`<div class="item" data-idx="${i}"></div>`)
      this.free.push(i);
    }

    this.dom.innerHTML = itemArr.join('')
    this.oItems = this.dom.getElementsByClassName(this.cls);
  }

  // 弹幕完成后重置 cls 以及 修改空闲弹幕项
  resetItemCls() {
    let oItems = [...this.oItems];
    let self = this;

    oItems.forEach((el, index) => {
      el.addEventListener('transitionend', () => {
        el.className = this.cls;
        this.free.push(index);
      });
    })
  }

  // 发射
  launch(str) {
    if (!str || this.free.length == 0) return;

    let len = this.free.length;

    let idx = this.free[Math.floor(Math.random() * len)];
    
    this.oItems[idx].innerHTML = str;
    this.oItems[idx].classList.add(this.activeCls)
    this.free.splice(this.free.indexOf(idx), 1);
    // console.log(this.oItems[idx])
  }
}

export default BulletChat